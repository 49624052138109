<template>
  <div>
    <EditDiscountModule
      @editDetail="editDetail"
      @cancelEdit="cancelEdit"
      @newProductAdd = "newProductAdd"
      @deleteTempItem = "deleteTempItem"
      @deleteItem="deleteItem"
      :discountId="discountId"
      :detail="detail"
    />
  </div>
</template>

<script>
  import EditDiscountModule from "../../components/webstore-discounts/EditDiscountModule";
  import { mapActions } from 'vuex';
  import _ from "lodash";

  export default {
    name: 'EditDiscount',
    components:{
      EditDiscountModule,
    },
    data: () => ({
      redirectRoute:'DiscountManagement',
      discountId: '',
      detail: {
        _id:'',
        name: '',
        discountType:'SpecificProduct',
        productDiscounts: [],
        checkoutLabel: ''
      }
    }),
    methods:{
      ...mapActions("discount", [
        "getDiscountDetails",
        "updateDiscountDetails"
      ]),
      ...mapActions("ecommerce",["algoliaSync"]),
      newProductAdd(product){
        if(_.find(this.detail.productDiscounts,{productStoreId:product._id})){
          this.$vs.notify({
            title: "Product Add",
            text: "Product has been already added below.",
            color: "danger",
          });
          return false;
        }
        this.detail.productDiscounts.push({
          name:product.name,
          productStoreId:product._id,
          price:product.price/100,
          isActive:true,
          adjustedPrice: null,
          costPrice: product.supplierCostPrice
        })
      },
      async fetchDetail(id){
        const discount =await this.getDiscountDetails(id)
        const discountData = discount.data.data;
        discountData.productDiscounts.forEach(function (value) {
           value.price = value.price/100;
           value.adjustedPrice = value.adjustedPrice/100;
           value.costPrice = (value.costPrice || 0)/100;
        })
        this.detail = discountData
      },
      editDetail(data){
        let self=this;
        let value = {
          id: this.discountId,
          info: data
        };
        console.log(value)
        this.updateDiscountDetails(value).then(res => {
          self.algoliaSync()
          self.$vs.notify({
            title: "Discount Edit",
            text: "Discount edited successfully",
            color: "success",
          });
          self.$vs.loading.close();
          this.$router.push({ name: this.redirectRoute})
        });
      },
      cancelEdit() {
        this.$router.push({ name: this.redirectRoute});
      },

      async deleteTempItem(discount) {
        const id = discount.productStoreId
        const arr = this.detail.productDiscounts

        // _.remove(this.detail.productDiscounts, (item) => {
        //     return item.productStoreId === id;
        // });

        const productIndex = _.findIndex(arr, { productStoreId: id });
        if (productIndex !== -1) {
            arr.splice(productIndex, 1);
        }
      },
      
      async deleteItem(discount, product){
        this.$vs.loading();
        const data = {
          discount: discount._id,
          product: product
        };

        await this.deleteProductInDiscountById(data)
        .then(res => {
          if(res.status === 200) {
            this.$vs.notify({
              title: "Product removed",
              text: "Product removed successfully.",
              color: "success",
            });
            location.reload();
          }
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: "Product not removed",
            text: "Failed to remove product.",
            color: "danger",
          });
        }).finally((_) => {
            this.$vs.loading.close();
        }) 
      }
    },
    async created() {
      this.discountId = this.$route.params.discountId;
      this.fetchDetail(this.discountId)

    }
  };
</script>
